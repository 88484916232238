import i18n from '../lang/index'
//字典
export default {
    //商品审核状态: 0下架,1上架审核通过, 2 待审核,3未通过
    goodsAuditStatus: [{
        code: 0,
        text: i18n.t('assets.dict.697759-0'),
    },
    {
        code: 1,
        text: i18n.t('assets.dict.697759-1'),
    },
    {
        code: 2,
        text: i18n.t('assets.dict.697759-2'),
    },
    {
        code: 3,
        text: i18n.t('assets.dict.697759-3'),
    },
    ],
    //专区类别：1一号专区；2非一号专区
    zoneTypes: [{
        code: 1,
        text: i18n.t('assets.dict.697759-4'),
    },
    {
        code: 2,
        text: i18n.t('assets.dict.697759-5'),
    },
    ],
    //是否包邮：1.包邮，0不包邮
    isContainFreight: [{
        code: 0,
        text: i18n.t('assets.dict.697759-6'),
    },
    {
        code: 1,
        text: i18n.t('assets.dict.697759-7'),
    },
    ],
    // 支付状态（0：支付失败 1：支付成功 2：待支付 3：线下已付款 102：申请售后）
    paymentState: [{
        code: 0,
        text: i18n.t('assets.dict.697759-8'),
    },
    {
        code: 1,
        text: i18n.t('assets.dict.697759-9'),
    },
    {
        code: 2,
        text: i18n.t('assets.dict.697759-10'),
    },
        // {
        //     code: 3,
        //     text: "线下已付款",
        // },
        // {
        //     code: 102,
        //     text: "申请售后",
        // },
    ],
    //  订单状态  0待支付,3.待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
    orderState: [{
        code: 0,
        text: i18n.t('assets.dict.697759-10'),
    },
    {
        code: 3,
        text: i18n.t('assets.dict.697759-11'),
    },
    {
        code: 4,
        text: i18n.t('assets.dict.697759-12'),
    },
    {
        code: 5,
        text: i18n.t('assets.dict.697759-13'),
    },
    {
        code: 6,
        text: i18n.t('assets.dict.697759-14'),
    },
    {
        code: 7,
        text: i18n.t('assets.dict.697759-15'),
    },
    {
        code: 8,
        text: i18n.t('assets.dict.697759-16'),
    },
    ],
    //   付款方式1微信,2支付宝,3paypal10购物劵,11支付宝+购物劵,12微信+购物劵25:PaymentAsia-支付宝 26:PaymentAsia-微信27:PaymentAsia-CUP 28:PaymentAsia-CreditCard 29:PaymentAsia-Fps
    paymentType: [{
        code: 1,
        text: i18n.t('assets.dict.697759-17'),
    },
    {
        code: 2,
        text: i18n.t('assets.dict.697759-18'),
    },
    {
        code: 3,
        text: "paypal",
    },
    {
        code: 10,
        text: i18n.t('assets.dict.697759-19'),
    },

    {
        code: 11,
        text: i18n.t('assets.dict.697759-20'),
    },
    {
        code: 12,
        text: i18n.t('assets.dict.697759-21'),
    },
    {
        code: 104,
        text: i18n.t('assets.dict.697759-22'),
    },

    {
        code: 25,
        text: 'PaymentAsia-支付宝',
    },

    {
        code: 26,
        text: 'PaymentAsia-微信',
    },


    {
        code: 27,
        text: 'PaymentAsia-CUP',
    },

    {
        code: 28,
        text: 'PaymentAsia-CreditCard',
    },

    {
        code: 29,
        text: 'PaymentAsia-Fps',
    },

    {
        code: 33,
        text: '银行卡支付',
    },
    {
        code: 34,
        text: i18n.t('assets.dict.697759-46'),
    },




    ],
    //  订单状态 0待支付,3待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
    orderStatus: [{
        code: 0,
        text: i18n.t('assets.dict.697759-10'),
    },
    {
        code: 1,
        text: i18n.t('assets.dict.697759-23'),
    },
    {
        code: 3,
        text: i18n.t('assets.dict.697759-11'),
    },
    {
        code: 4,
        text: i18n.t('assets.dict.697759-12'),
    },
    {
        code: 5,
        text: i18n.t('assets.dict.697759-13'),
    },
    {
        code: 6,
        text: i18n.t('assets.dict.697759-14'),
    },
        // {
        //     code: 7,
        //     text: i18n.t('assets.dict.697759-15'),
        // },
        // {
        //     code: 8,
        //     text: i18n.t('assets.dict.697759-16'),
        // },
    ],
    //退款状态
    refundState: [{
        code: 0,
        text: i18n.t('assets.dict.697759-24'),
    }, {
        code: 1,
        text: i18n.t('assets.dict.697759-25'),
    }, {
        code: 2,
        text: i18n.t('assets.dict.697759-26'),
    }],
    //商品参与活动状态：0已下架 1上架中 2待上架
    goodsActivityStatus: [{
        code: 0,
        text: i18n.t('assets.dict.697759-27')
    }, {
        code: 1,
        text: i18n.t('assets.dict.697759-28')
    }, {
        code: 2,
        text: i18n.t('assets.dict.697759-29')
    }],
    //进货订单 ---订单状态
    restockOrderState: [{
        code: 0,
        text: i18n.t('assets.dict.697759-10')
    },
    {
        code: 3,
        text: i18n.t('assets.dict.697759-11')
    },
    {
        code: 4,
        text: i18n.t('assets.dict.697759-30')
    },
    {
        code: 5,
        text: i18n.t('assets.dict.697759-31')
    }
    ],
    // 开售状态
    canBuyStatus: [{
        code: 1,
        text: i18n.t('assets.dict.697759-32')
    }, {
        code: 0,
        text: i18n.t('assets.dict.697759-33')
    }],
    //物流状态
    wuliuStatus: [{
        code: 0,
        text: i18n.t('assets.dict.697759-34')
    },
    {
        code: 1,
        text: i18n.t('assets.dict.697759-35')
    },
    {
        code: 2,
        text: i18n.t('assets.dict.697759-36')
    },
    {
        code: 3,
        text: i18n.t('assets.dict.697759-37')
    },
    {
        code: 4,
        text: i18n.t('assets.dict.697759-38')
    },
    {
        code: 5,
        text: i18n.t('assets.dict.697759-39')
    },
    {
        code: 6,
        text: i18n.t('assets.dict.697759-40')
    },
    {
        code: 7,
        text: i18n.t('assets.dict.697759-41')
    },
    {
        code: 8,
        text: i18n.t('assets.dict.697759-42')
    },
    {
        code: 9,
        text: i18n.t('assets.dict.697759-43')
    },
    ]

};